import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { DataPropsPageGeneric } from "../../contracts/data-props-page"
import {
  IntencjeFormatSectionWrapper,
  IntencjeSectionWrapper,
} from "../../styles/intencje"
import { intencjeFormatterNew } from "../../helpers/intencje-formatter"
import { MarkdownRemark } from "../../contracts/markdown-remark"

export interface IntencjeMarkdownRemark extends MarkdownRemark {
  frontmatter: {
    title: string
    description?: string
    date?: string
    thumbnail?: string
    layout: string
    isFormat: boolean
  }
}

const Intencje: React.FC<
  PageProps<DataPropsPageGeneric<IntencjeMarkdownRemark>>
> = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout title={post.frontmatter.title} location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        {post.frontmatter.isFormat ? (
          <IntencjeFormatSectionWrapper
            dangerouslySetInnerHTML={{
              __html: intencjeFormatterNew(post.html),
            }}
            itemProp="articleBody"
          />
        ) : (
          <IntencjeSectionWrapper
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        )}
      </article>
    </Layout>
  )
}

export default Intencje

export const query = graphql`
  {
    markdownRemark(frontmatter: { layout: { eq: "intencje" } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        isFormat
        date(formatString: "DD MMMM YYYY", locale: "pl")
      }
    }
  }
`
